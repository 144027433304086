function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { createElement, useEffect } from 'react';
import { SuspenseHandler } from 'apps/acp/packages/react-suspense-handler';
import { AppBar } from './app-bar';
import { useTheme } from './theme';
import { LayoutBody, StyledLayout } from './unauthed-layout.ccm.css';
import { LogoProvider } from 'apps/acp/packages/logo-locator';
import { UnauthedFooterWrapper } from './unauthed-footer-wrapper';
import { AcquireNewCustomerContext } from 'apps/acp/packages/acquire-new-customer';
import { useBehaviorSubject } from 'packages/behavior-subject/react';
import { PermissionProvider } from './permissions';
import { ConnectProviderToSubscription } from 'apps/acp/packages/connect-provider-to-subscription';
import { EnvChooserProvider } from 'apps/acp/packages/env-chooser';

const PermissionProviderAdapter = ({
  children,
  value
}) => createElement(PermissionProvider, {
  permissionResolver: value
}, children);

const InternalLayout = ({
  logoLocator,
  AcquireNewCustomer,
  children,
  appBarInverted,
  statusBarPlugin,
  statusBarBackgroundColor,
  statusBarForegroundColor,
  variantId,
  version,
  mode,
  copyrightStatement,
  loyaltyProgramDetailDisclaimer,
  fasterFundingDisclaimer,
  paybackRewardsDisclaimer,
  cardDisclaimer,
  importantPatriotActDisclosure,
  programType,
  // showRegisterLink,
  // showMarketingSiteLink,
  marketingSiteUrl,
  // marketingSiteLinkText,
  // contactLinks,
  // activateCardLinks,
  // registerLinks,
  // locationFinderLinks,
  // logoutLinks,
  // loginLinks,
  // showReloadLocationLink,
  // gaCategory,
  // gaLabel,
  // showFooterLinks,
  showFooter,
  // atmFinderLinks,
  // showFreeAtmFinderLink,
  // showAcquisitionLink,
  // openAccountFooterContent,
  permissionsResolverSubscribe,
  // clearSessionStorage,
  styledLayoutColor // layoutBodyColor

}) => {
  const acquireNewCustomerInstance = useBehaviorSubject(AcquireNewCustomer);
  const {
    layoutGreyBackgroundColor
  } = useTheme();
  useEffect(() => {
    statusBarPlugin.setStatusBarColor(statusBarBackgroundColor, statusBarForegroundColor);
  }, [statusBarPlugin, statusBarBackgroundColor, statusBarForegroundColor]);
  return createElement(EnvChooserProvider, null, createElement(AcquireNewCustomerContext.Provider, {
    value: acquireNewCustomerInstance
  }, createElement(ConnectProviderToSubscription, {
    subscribe: permissionsResolverSubscribe,
    Provider: PermissionProviderAdapter
  }, createElement(LogoProvider, {
    logoLocator: logoLocator
  }, createElement(StyledLayout.div, {
    $backgroundColor: styledLayoutColor ? styledLayoutColor : layoutGreyBackgroundColor,
    "data-testid": variantId
  }, createElement(SuspenseHandler, null, createElement(AppBar, {
    marketingSiteUrl: marketingSiteUrl,
    inverted: appBarInverted,
    mode: mode,
    statusBarBackgroundColor: statusBarBackgroundColor
  })), createElement(LayoutBody.div, {
    $backgroundColor: "#FFFF"
  }, createElement(SuspenseHandler, null, children)), showFooter && createElement(UnauthedFooterWrapper, {
    version,
    copyrightStatement,
    importantPatriotActDisclosure,
    programType,
    loyaltyProgramDetailDisclaimer,
    fasterFundingDisclaimer,
    paybackRewardsDisclaimer,
    cardDisclaimer
  }))))));
};

export const createUnauthedLayoutv2 = dependencies => props => createElement(InternalLayout, _extends({}, dependencies, props));