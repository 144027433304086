
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledLayout_e2921a22dd70025d{margin:43px 0 0}.LayoutBody_8a751557163cf7e5,.StyledLayout_e2921a22dd70025d{background-color:var(--backgroundColor_ffd374a13e0af3d2)}.MainSection_e440ee660b168139{padding:0;width:100%;margin-top:0;margin-bottom:0}@media (min-width:600px){.LayoutBody_8a751557163cf7e5{margin:0}}`;
styleInject(_css)

/** StyledLayout Props */
export type StyledLayoutCCM = {
  /** StyledLayout Component Custom Properties */
  '$backgroundColor': string;

  /** StyledLayout Modifier Flags */
  // No modifiers classes found
};
/** Base StyledLayout component */
export const StyledLayout: ComponentCreator<StyledLayoutCCM> = createComponentCreator({
  "name": "StyledLayout",
  "base": "StyledLayout_e2921a22dd70025d",
  "prop": {
    "$backgroundColor": "--backgroundColor_ffd374a13e0af3d2"
  },
  "mod": {}
});


/** LayoutBody Props */
export type LayoutBodyCCM = {
  /** LayoutBody Component Custom Properties */
  '$backgroundColor': string;

  /** LayoutBody Modifier Flags */
  // No modifiers classes found
};
/** Base LayoutBody component */
export const LayoutBody: ComponentCreator<LayoutBodyCCM> = createComponentCreator({
  "name": "LayoutBody",
  "base": "LayoutBody_8a751557163cf7e5",
  "prop": {
    "$backgroundColor": "--backgroundColor_ffd374a13e0af3d2"
  },
  "mod": {}
});


/** MainSection Props */
export type MainSectionCCM = {
  /** MainSection Component Custom Properties */
  // No custom properties found

  /** MainSection Modifier Flags */
  // No modifiers classes found
};
/** Base MainSection component */
export const MainSection: ComponentCreator<MainSectionCCM> = createComponentCreator({
  "name": "MainSection",
  "base": "MainSection_e440ee660b168139",
  "prop": {},
  "mod": {}
});

