
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.DisclosureContainer_326a1ffd2d179109{background:#fff;padding:16px}`;
styleInject(_css)

/** DisclosureContainer Props */
export type DisclosureContainerCCM = {
  /** DisclosureContainer Component Custom Properties */
  // No custom properties found

  /** DisclosureContainer Modifier Flags */
  // No modifiers classes found
};
/** Base DisclosureContainer component */
export const DisclosureContainer: ComponentCreator<DisclosureContainerCCM> = createComponentCreator({
  "name": "DisclosureContainer",
  "base": "DisclosureContainer_326a1ffd2d179109",
  "prop": {},
  "mod": {}
});

